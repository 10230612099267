var EmptyGuid = "00000000-0000-0000-0000-000000000000";

export class DataValidation {
    CheckGuidValidation(guid) {
        if (
            guid != undefined &&
            guid != null &&
            guid != EmptyGuid &&
            guid != ""
        )
            return true;
        else return false;
    }

    CheckStringValidation(text) {
        if (!text || text == null || text == "" || text == " ") return false;
        return true;
    }
}
