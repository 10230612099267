import axios from "axios";

export default class AccountsApi {
    static GetUserPermissionsInfo(roleType) {
        return axios
            .get("Accounts/GetUserPermissionsInfo", {
                params: { roleType: roleType }
            })
            .then(response => response);
    }

    static GetUserPositions(userGuid) {
        return axios
            .get("Accounts/GetUserPositions?userGuid=" + userGuid)
            .then(response => response);
    }

    static SavePermissions(dto) {
        return axios
            .post("Accounts/SaveUserPositions", dto)
            .then(response => response);
    }
}
