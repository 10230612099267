import axios from "axios";

export default class AlertsApi {
    static Get(id = null) {
        if (id != null)
            return axios.get("Alerts/Get?id=" + id).then(response => response);
        return axios.get("Alerts/Get").then(response => response);
    }

    static GetAlertDestinations(id = null) {
        return axios
            .get("Alerts/GetAlertDestinations?id=" + id)
            .then(response => response);
    }

    static Create = alert => {
        return axios.post("Alerts/Create", alert).then(response => response);
    };

    static UpdateAttachments(data) {
        return axios
            .post("Alerts/UpdateAttachments", data)
            .then(response => response);
    }

    static Delete = guid => {
        return axios
            .delete("Alerts/Delete?guid=" + guid)
            .then(response => response);
    };

    static DeleteAlertAttachment(fileName, BaseGuid) {
        return axios
            .post("Alerts/DeleteAlertAttachment", {
                fileName: fileName,
                baseGuid: BaseGuid
            })
            .then(response => response);
    }
}
