<template>
    <v-container fluid>
        <v-card :loading="loading">
            <Page-Header
                :title="$t('alerts.alertToDepartments')"
                icon="mdi-office-building"
            />
            <v-system-bar v-if="disabled" color="orange darken-1" height="30">
                {{ $t("alerts.ui.cannotEditAlertAfterCreateIt") }}
            </v-system-bar>
            <v-card-text>
                <v-form lazy-validation ref="form" v-model="valid">
                    <v-row>
                        <v-col cols="12" md="12">
                            <v-text-field
                                :disabled="disabled"
                                hide-details
                                :outlined="outlined"
                                :dense="dense"
                                v-model="editedAlert.title"
                                :rules="rules"
                                :label="$t('alerts.title')"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="12">
                            <tiptap-vuetify
                                v-model="editedAlert.details"
                                :card-props="{
                                    outlined
                                }"
                                :extensions="extensions"
                                :disabled="disabled"
                                :toolbar-attributes="{
                                    color: $vuetify.theme.dark ? '' : '#EEEEEE'
                                }"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-autocomplete
                                :outlined="outlined"
                                :items="sendersEmployees"
                                @change="senderEmployeeChanged"
                                :disabled="disabled"
                                item-value="employeeGuid"
                                item-text="employeeName"
                                v-model="editedAlert.senderEmployeeGuid"
                                :rules="rules"
                                hide-details
                                :label="$t('alerts.ui.alertAs')"
                                :dense="dense"
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12">
                            <v-autocomplete
                                :items="filteredDepartments"
                                :disabled="disabled"
                                :outlined="outlined"
                                hide-details
                                item-value="departmentGuid"
                                item-text="departmentName"
                                chips
                                :label="$t('alerts.ui.alertToDepartments')"
                                clearable
                                :rules="listRules"
                                v-model="alertToDepartments"
                                deletable-chips
                                :multiple="selectMultipleDepartments"
                                :dense="dense"
                                small-chips
                                ><template slot="prepend-item">
                                    <v-btn
                                        text
                                        block
                                        @click="selectAllDepartments"
                                        >{{ $t("selectAll") }}</v-btn
                                    >
                                </template>
                                <template v-slot:selection="{ item, index}" >
                                    <v-chip
                                        v-if=" index === 0 || index === 1 || index === 2" :disabled="disabled" >
                                        <span>{{ item.departmentName }}</span>
                                    </v-chip>
                                    <span
                                        v-if="index === 3"
                                        class="grey--text text-caption">
                                        (+{{ alertToDepartments.length - 3 }}
                                        {{ $t("others") }})
                                    </span>
                                </template>
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            v-if="
                                alertFiles != undefined &&
                                    (alertFiles != null) &
                                        (alertFiles.length > 0)
                            "
                            cols="12"
                            md="12"
                        >
                            <v-row no-gutters>
                                <v-col cols="12"
                                    ><p class="black--text">
                                        {{ $t("alerts.alertAttachments") }}
                                    </p></v-col
                                >
                                <v-col cols="12">
                                    <v-card
                                        class="overflow-y-auto"
                                        max-height="300"
                                        elevation="0"
                                    >
                                        <v-card-text>
                                            <v-row>
                                                <v-col
                                                    v-for="file in alertFiles"
                                                    :key="file.name"
                                                    cols="12"
                                                    md="4"
                                                >
                                                    <Download-File
                                                        :iconWidth="50"
                                                        :fileSizeFormat="
                                                            file.size
                                                        "
                                                        :disabled="disabled"
                                                        :onDelete="
                                                            openDeleteAttachmentDialog
                                                        "
                                                        :onClose="
                                                            closeDeleteAttachmentDialog
                                                        "
                                                        :file="file"
                                                        :fileUrl="file.path"
                                                    ></Download-File>
                                                </v-col> </v-row
                                        ></v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" md="12">
                            <v-file-input
                                v-model="currFiles"
                                small-chips
                                :outlined="outlined"
                                show-size
                                :dense="dense"
                                :label="$t('attachments')"
                                multiple
                                :disabled="disabled"
                                hide-details
                                @click:clear="clearAttachments"
                                @change="attachmentsInputChanged"
                            >
                                <template
                                    v-slot:selection="{
                                        index,
                                        text
                                    }"
                                >
                                    <v-chip
                                        close
                                        @click:close="
                                            deleteAttachmentChip(index)
                                        "
                                    >
                                        {{ text }}
                                    </v-chip>
                                </template>
                            </v-file-input>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn
                    :disabled="!valid || disabled"
                    v-if="
                        isInRole(roles.alert.create) ||
                            isInRole(roles.alert.update)
                    "
                    :loading="saveLoading"
                    @click="saveAlert"
                    min-width="80"
                    color="primary"
                >
                    <v-icon>mdi-content-save-outline</v-icon>
                    {{
                        !disabled
                            ? $t("save")
                            : $t("save") + " " + $t("attachments")
                    }}
                </v-btn>
            </v-card-actions>
            <confirm-dialog
                :openDialog="deleteFileDialog"
                :onClicked="deleteAttachment"
                :onClose="closeDeleteAttachmentDialog"
                toolBarColor="red darken-2"
            ></confirm-dialog>
        </v-card>
    </v-container>
</template>
<script>
import PageHeader from "../../components/PageHeader.vue";
import DownloadFile from "../../components/downloadFile.vue";
import ConfirmDialog from "../../components/ConfirmDialog.vue";
import AccountsApi from "../../Api/AccountsApi.js";
import AlertsApi from "../../Api/AlertsApi.js";
import { DataValidation } from "../../BusinessLogic/DataValidation";

const dataValidation = new DataValidation();

import {
    TiptapVuetify,
    Heading,
    Bold,
    Italic,
    Strike,
    Underline,
    Code,
    Paragraph,
    BulletList,
    OrderedList,
    ListItem,
    Link,
    Blockquote,
    HardBreak,
    HorizontalRule,
    History
} from "tiptap-vuetify";

export default {
    components: {
        PageHeader,
        DownloadFile,
        TiptapVuetify,
        ConfirmDialog
    },
    created() {
        if (
            !this.isInRole(this.roles.alert.create) &&
            !this.isInRole(this.roles.alert.update)
        )
            this.redirectUnauthorizedUsers();

        this.getUserPermissionsInfo();

        if (dataValidation.CheckGuidValidation(this.alertGuid)) {
            this.getAlert(this.alertGuid);
            this.disabled = true;
        }
    },
    data() {
        return {
            alertGuid: this.$route.params.id,
            selectMultipleDepartments: true,
            sendersEmployees: [],
            alertToDepartments: [],
            departments: [],
            filteredDepartments: [],
            editedAlert: {},
            loading: false,
            saveLoading: false,
            valid: true,
            disabled: false,
            outlined: true,
            dense: false,
            currFiles: [],
            files: [],
            alertFiles: [],
            fileNameWithDirectory: {},
            deleteFileDialog: false,
            extensions: [
                History,
                Blockquote,
                Link,
                Underline,
                Strike,
                Italic,
                ListItem,
                BulletList,
                OrderedList,
                [
                    Heading,
                    {
                        options: {
                            levels: [1, 2, 3]
                        }
                    }
                ],
                Bold,
                Code,
                HorizontalRule,
                Paragraph,
                HardBreak
            ],
            rules: [value => !!value || this.$t("required")],
            listRules: [v => v.length > 0 || this.$t("required")]
        };
    },
    methods: {
        pageRedirection(alertGuid) {
            if (
                dataValidation.CheckGuidValidation(alertGuid) &&
                this.isInRole(this.roles.alert.update)
            )
                this.redirectToSamePage(alertGuid);
            else this.redirectToAlertsPage();
        },
        redirectToSamePage(alertGuid) {
            this.$router.push({
                name: "alerts.alertToDepartments",
                params: {
                    id: alertGuid
                }
            });
        },
        redirectToAlertsPage() {
            this.$router.push({
                name: "alerts.alerts"
            });
        },
        deleteAttachment() {
            AlertsApi.DeleteAlertAttachment(
                this.fileNameWithDirectory,
                this.alertGuid
            )
                .then(response => {
                    if (response.data.status == this.responseStatus.ok) {
                        this.$toast.success(
                            this.$t("operationAccomplishedSuccessfully")
                        );
                        this.getAlert(this.editedAlert.alertGuid);
                    } else {
                        this.$toast.error(
                            this.$t("error." + response.data.message)
                        );
                    }
                })
                .catch(error => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    console.log(error);
                })
                .finally(() => {
                    this.closeDeleteAttachmentDialog();
                });
        },
        getAlert(alertGuid) {
            this.loading = true;
            AlertsApi.Get(alertGuid)
                .then(response => {
                    this.editedAlert = response.data.data;
                    this.alertToDepartments = response.data.data.departments;
                    this.alertFiles = response.data.data.attachments;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        saveAlert() {
            if (!this.$refs.form.validate()) return;

            if (!dataValidation.CheckGuidValidation(this.editedAlert.alertGuid))
                this.createALert();
            else this.updateALert();
        },
        createALert() {
            try {
                var data = new FormData();

                for (let i = 0; i < this.files.length; i++) {
                    var file = this.files[i];
                    if (file != null && file.size <= 20971520)
                        data.append("files", file);
                    else {
                        this.$toast.error(this.$t("error.MaxFileSizeReached"));
                        return;
                    }
                }

                for (let i = 0; i < this.alertToDepartments.length; i++) {
                    data.append("departments", this.alertToDepartments[i]);
                }

                var dto = {
                    title: this.editedAlert.title,
                    details: this.editedAlert.details,
                    senderEmployeeGuid: this.editedAlert.senderEmployeeGuid
                };

                for (var key in dto) {
                    if (dto[key]) data.append(key, dto[key]);
                }

                this.loading = true;
                AlertsApi.Create(data)
                    .then(response => {
                        if (response.data.status == this.responseStatus.ok) {
                            this.$toast.success(
                                this.$t("operationAccomplishedSuccessfully")
                            );
                            this.pageRedirection(response.data.data);
                        } else {
                            this.$toast.error(
                                this.$t("error." + response.data.message)
                            );
                        }
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        console.log(e);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            } catch (error) {
                console.log(error);
            }
        },
        updateALert() {
            try {
                if (this.files.length == 0) return;

                var data = new FormData();

                data.append("alertGuid", this.editedAlert.alertGuid);

                for (let i = 0; i < this.files.length; i++) {
                    var file1 = this.files[i];
                    if (file1 != null && file1.size <= 20971520) {
                        data.append("files", file1);
                    } else {
                        this.$toast.error(this.$t("error.MaxFileSizeReached"));
                        return;
                    }
                }

                this.loading = true;

                AlertsApi.UpdateAttachments(data)
                    .then(response => {
                        if (response.data.status == this.responseStatus.ok) {
                            this.$toast.success(
                                this.$t("operationAccomplishedSuccessfully")
                            );
                            this.files = [];
                            this.currFiles = [];
                            this.getAlert(this.alertGuid);
                        } else {
                            this.$toast.error(
                                this.$t("error." + response.data.message)
                            );
                        }
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        console.log(e);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            } catch (error) {
                console.log(error);
            }
        },
        openDeleteAttachmentDialog(fileNameWithDirectory) {
            this.fileNameWithDirectory = fileNameWithDirectory;
            this.deleteFileDialog = true;
        },
        closeDeleteAttachmentDialog() {
            this.deleteFileDialog = false;
            this.currFiles = [];
            this.files = [];
        },
        clearAttachments() {
            this.files = [];
        },
        attachmentsInputChanged() {
            this.files = [...this.files, ...this.currFiles];
        },
        deleteAttachmentChip(index) {
            this.files.splice(index, 1);
            this.currFiles.splice(index, 1);
        },
        selectAllDepartments() {
            this.alertToDepartments = this.filteredDepartments.map(
                department => {
                    return department.departmentGuid;
                }
            );
        },
        getUserPermissionsInfo() {
            AccountsApi.GetUserPermissionsInfo(0).then(response => {
                this.departments = response.data.data.departments;
                this.filteredDepartments = response.data.data.departments;
                this.sendersEmployees = response.data.data.employees;
                this.loading = false;
            });
        },
        senderEmployeeChanged(guid) {
            var sender = this.sendersEmployees.find(
                w => w.employeeGuid == guid
            );

            if (sender == null) return;

            if (sender.employeePositionType == 5) {
                var selectedDepartment = this.departments.find(
                    w => w.departmentGuid == sender.departmentGuid
                );

                this.filteredDepartments = this.getDepartmentChildren(
                    selectedDepartment.departmentGuid
                );
            } else {
                this.filteredDepartments = this.departments;
            }
        },
        getDepartmentChildren(parentGuid) {
            try {
                var departments = [];

                departments.push(
                    this.departments.find(w => w.departmentGuid == parentGuid)
                );

                var children = this.departments.filter(
                    w => w.parentDepartmentGuid == parentGuid
                );

                while (children.length != 0) {
                    children.forEach(w => {
                        departments.push(w);
                    });

                    var guids = children.map(w => w.departmentGuid);

                    children = this.departments.filter(w =>
                        guids.includes(w.parentDepartmentGuid)
                    );
                }

                return departments;
            } catch (error) {
                console.log(error);
            }
        }
    }
};
</script>
